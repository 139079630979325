import PropTypes from 'prop-types';

const propType = {
  code: PropTypes.string.isRequired,
  decimal_digits: PropTypes.number,
  name: PropTypes.string.isRequired,
  name_plural: PropTypes.string,
  rounding: PropTypes.number,
  symbol: PropTypes.string.isRequired,
  symbol_native: PropTypes.string,
};

export default { propType };
