import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import SelectFilter from '../../../../commonComponents/lists/filters/SelectFilter';
import WarningCard from '../../../../commonComponents/cards/WarningCard';
import CurrencyProp from '../../../profile/propTypes/currencyProp';

function TabCurrencies(props) {
  // TODO: After migration of Organization page to React, use "useContext" to get information
  const { t, currencies, organizationCurrencies, businessCurrency, onChange } =
    props;

  return (
    <div id="tabAvailableCurrencies">
      <input
        type="hidden"
        id="organizationAvailableCurrencies"
        name="organizationAvailableCurrencies"
        value={JSON.stringify(organizationCurrencies)}
      />
      <input
        type="hidden"
        id="organizationBusinessCurrency"
        name="organizationBusinessCurrency"
        value={JSON.stringify(businessCurrency)}
      />
      <section>
        <h4>{t('currency')}</h4>
        <SelectFilter
          id="currenciesSelector"
          placeholder={t('currencies')}
          label={t('settings.configurations.currency.available.label')}
          hint={t('settings.configurations.currency.available.hint')}
          onChange={onChange('currencies')}
          initialValue={organizationCurrencies}
          multiple
          items={currencies.map(c => ({
            id: c.code,
            text: `${c.name} ( ${c.symbol} )`,
          }))}
        />
        <SelectFilter
          id="defaultCurrencySelector"
          placeholder={t('settings.configurations.currency.default.label')}
          label={t('settings.configurations.currency.default.label')}
          onChange={onChange('businessCurrency')}
          initialValue={businessCurrency?.toUpperCase()}
          items={currencies.map(c => ({
            id: c.code,
            text: `${c.name} ( ${c.symbol} )`,
          }))}
        />
        <WarningCard wrapperClass="warning-currencies">
          <span>{t('settings.configurations.currency.available.warning')}</span>
        </WarningCard>
      </section>
    </div>
  );
}

TabCurrencies.propTypes = {
  t: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.shape(CurrencyProp.propType))
    .isRequired,
  organizationCurrencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  businessCurrency: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
TabCurrencies.defaultProps = {};

export default translate(TabCurrencies);
