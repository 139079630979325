import React from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';

const WarningCard = ({ children, wrapperClass }) => (
  <div className={`warning-card ${wrapperClass || ''}`}>{children}</div>
);

WarningCard.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClass: PropTypes.string,
};
WarningCard.defaultProps = {
  wrapperClass: '',
};

export default translate(WarningCard);
